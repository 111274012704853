<template>
  <v-container id="my-orders">
    <CheckoutTopBar
      @go-back-action="goToMainFeed"
      @show-nav-drawer="showNavDrawer = true"
    />

    <div class="my-page-content">
      <v-row class="mb-4">
        <v-col cols="12">
          <div class="title-10">
            Completed ({{myOrders.length}})
          </div>
        </v-col>
      </v-row>

      <v-list flat id="orders-list">
        <div v-for="(item, index) in myOrders">
          <v-list-item
            :key="`order-${index}`"
            class="pa-0"
            @click="openOrderDetails(item)"
          >
            <v-list-item-content class="text-left py-2">
              <v-list-item-title>
                <div class="text-uppercase small-text mb-1">
                  {{item.order_status != undefined ? item.order_status : ''}}
                </div>
                <div class="mb-2 d-flex align-center">
                  <div class="title-7">{{item.order_date}}</div>
                  <div class="body-3 ml-4">
                    {{item.order_total.toFixed(2)}} &euro;
                  </div>
                </div>
                <div class="body-3 dishes-line">{{joinDishNames(item.dishes)}}</div>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon size="13" color="#000000">{{right_arrow_btn}}</v-icon>
            </v-list-item-action>
          </v-list-item>

          <!-- Reviews to be added in another version -->
          <!-- <div v-if="!item.order_reviewed">
            <v-btn
              text
              class="add-review-btn"
              @click="showReviewOverlay = true"
            >
              Add review
            </v-btn>
          </div>
          <div v-else class="reviewed-container">
            <div class="body-3">Reviewed</div>
            <div class="ml-1">
              <v-img src="@/assets/reviewed-icon.svg" width="14" height="14"></v-img>
            </div>
          </div> -->

          <v-divider class="my-divider mb-4"></v-divider>
        </div>
      </v-list>
    </div>

    <!-- <ReviewOverlay
      :visible="showReviewOverlay"
      @close="showReviewOverlay = false"
    /> -->
    
    <NavigationDrawer
      :visible="showNavDrawer"
      @close="showNavDrawer = false"
    />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import CheckoutTopBar from '@/components/Navigation/CheckoutTopBar';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';
  import ReviewOverlay from '@/components/MainFeed/ReviewOverlay';

  export default {
    name: 'MyOrders',
    components: {
      CheckoutTopBar,
      NavigationDrawer,
      ReviewOverlay
    },
    computed: {
      ...mapGetters([
        "myOrders"
      ]),
    },
    data: () => ({
      showNavDrawer: false,
      showAddCommentOverlay: false,
      showReviewOverlay: false,
      changeQuantity: false,
      right_arrow_btn: "$vuetify.icons.right_arrow_btn"
    }),
    methods: {
      ...mapActions([
        "setOrderDetails"
      ]),
      goToMainFeed() {
        this.$router.push({name: 'menu'});
      },
      joinDishNames(array) {
        let result = '';
        for (let k = 0; k < array.length; k++) {
          result += array[k].title;
          if (k != array.length - 1) {
            result += ', ';
          }
        }
        return result;
      },
      openOrderDetails(order) {
        this.setOrderDetails(order).then(() => {
          this.$router.push({name: 'order_details'});
        })
      }
    }
  };
</script>

<style type="text/css">
  #my-orders {
    padding: 20px;
  }

  .dishes-line {
    max-width: 250px;
    white-space: break-spaces;
  }

  #my-orders .add-review-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #001254;
    padding: 0;
  }

  #orders-list .v-list-item__title {
    text-overflow: unset;
    white-space: pre-wrap;
  }

  #orders-list .v-list-item__action {
    justify-content: flex-end;
    min-width: 13px;
    margin-left: 0;
  }

  .reviewed-container {
    display: flex;
    align-items: flex-end;
    padding: 8px 0 20px;
  }

  .reviewed-container .body-3 {
    line-height: 12px;
  }
</style>
